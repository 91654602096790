.Page_number {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 20px;
  }
  
  .page_align {
    display: flex;
    justify-content: flex-start;
    gap: 50px;
    align-items: center;
  }
  
  .page_align label {
    color: #fff;
    font-weight: bold;
  }
  
  .page_align select {
    font-size: 15px;
    cursor: pointer;
    padding: 4px;
    border: none;
    border-radius: 2px 5px;
    outline: none;
  }
  