
.home {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
  color: rgb(8, 8, 19);
}

.mui-number-field 
{ input::-webkit-inner-spin-button, input::-webkit-outer-spin-button { -webkit-appearance: none; margin: 0; } }
.banner {
  text-align: center;
  color: whitesmoke;
  padding: 10px;
}

.banner p {
  font-size: 24px;
  max-width: 600px;
  margin: 0 auto;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

::-webkit-scrollbar {
  width: 5px; 
  max-height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #4c6a7b; 
  border-radius: 5px; 
}

::-webkit-scrollbar-track {
  background-color: #2e2828; 
  border-radius: 5px;

}
#canvas-container {
  position: relative;
  overflow:auto;
  /* flex: 1; */
  display: grid;
  justify-content:center;
    /* For non-Webkit browsers */
    scrollbar-color: #4B697A #ccc; /* Scroll thumb color (#888) and track color (#ccc) */
    scrollbar-width: thin; /* Set scrollbar width */
}
/* For Webkit-based browsers */
#canvas-container::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
    height: 10px; /* Height of the scrollbar (horizontal) */
}
#canvas-container::-webkit-scrollbar-track {
    background: #ccc; /* Grey background color for the track */
}
#canvas-container::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners for the thumb */
}
#canvas-container::-webkit-scrollbar-thumb:hover {
    background: #555; /* Darker color for the thumb on hover */
}

/* .canvas-container {
  border:1px solid red;
  position: relative !important;
  top: 50px;
  left: 50px;
} */
.ruler {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}
.ruler.horizontal {
  left: 0px;
  width: 100%;
  height: 30px;
}
.ruler.vertical {
  top: 0px;
  width: 30px;
  height: 100%;
}
.pages-images {
  border-radius: 10px;
  padding: 0;
  cursor: pointer;
  height: 150px;
  max-width: 100%;
}

.pages-images:hover {
  border: 1px solid black;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
